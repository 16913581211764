/* --------------------------
:: Template Name: Colorlib App - App Landing Page 
:: Template Author: Colorlib
:: Template Author URI: https://colorlib.com/
:: Description: Colorlib is a One Page based App Landing Template. It's very clean, creative & beautiful. It's super     responsive & ultra light.
:: Tags: Colorlib, App Landing, Apps, Responsive App Landing, Bootstrap 4, Free Theme, Free HTML Template, HTML5 Template etc.
:: Version: v1.0
:: Created: 11/03/2017
-------------------------- */

/* --- [Master Stylesheet] ----
:: 1.0 Base CSS Start
:: 2.0 Header Area CSS
:: 3.0 Wellcome Area CSS
:: 4.0 Special Area CSS
:: 5.0 Features Area CSS
:: 6.0 Video Area CSS
:: 7.0 Cool Facts Area CSS
:: 8.0 Screenshots Area CSS
:: 9.0 Pricing Plan Area CSS
:: 10.0 Testimonials Area CSS
:: 11.0 CTA Area CSS Start
:: 12.0 Team Area CSS Start
:: 13.0 Contact Area CSS Start
:: 14.0 Footer Area CSS Start
-------------------------- */

@import url('https://fonts.googleapis.com/css?family=Lora:400,500,700|Montserrat:400,500,700');
@import 'bootstrap.min.css';
@import 'animate.css';
@import 'magnific-popup.css';
@import 'owl.carousel.min.css';
@import 'slick.css';
@import 'font-awesome.min.css';
@import 'themify-icons.css';
@import 'ionicons.min.css';
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800;900&display=swap');
/* --------------------------
:: 1.0 Base CSS Start
-------------------------- */
* {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Nunito', sans-serif !important;
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    /* font-family: 'Lora', sans-serif !important; */
    font-weight: 400;
    color: #000;
    line-height: 1.2;
}

fontStyle {
        font-family: 'Nunito' !important;
}

strong{
    font-weight: bold !important;
}

li{
    list-style: circle !important;
    list-style-position: inside !important;
}

.section_padding_100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.section_padding_100_50 {
    padding-top: 100px;
    padding-bottom: 50px;
}
.backgroundLogin {
    /* Control the height of the image */
    background-color: #FCF9F4;
    /* Full height */
    width: 100vw;
    height: 100vh;
  
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
.section_padding_100_70 {
    padding-top: 100px;
    padding-bottom: 70px;
}

.section_padding_50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.section_padding_50_20 {
    padding-top: 50px;
    padding-bottom: 20px;
}

.section_padding_150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.section_padding_200 {
    padding-top: 200px;
    padding-bottom: 200px;
}

.section_padding_0_100 {
    padding-top: 0;
    padding-bottom: 100px;
}

.section_padding_70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.section_padding_0_50 {
    padding-top: 0;
    padding-bottom: 50px;
}

img {
    max-width: 100%;
    max-height: 100%;
}

.mt-15 {
    margin-top: 15px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-150 {
    margin-top: 150px;
}

.mr-15 {
    margin-right: 15px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-100 {
    margin-right: 100px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-100 {
    margin-bottom: 100px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-30 {
    margin-left: 30px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-100 {
    margin-left: 100px;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

#scrollUp {
    bottom: 0;
    font-size: 24px;
    right: 30px;
    width: 50px;
    background-color: #70bf8b;
    color: #fff;
    text-align: center;
    height: 50px;
    line-height: 50px;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

li {
    list-style: none;
}

p {
    /* color: #726a84; */
    font-size: 14px;
    font-weight: 300;
    margin-top: 0;
}

.heading-text > p {
    font-size: 16px;
}

.section-heading > h2 {
    font-weight: 300;
    color: #70bf8b;
    font-size: 48px;
    margin: 0;
}
.section-heading > h3 {
    font-weight: 500;
    color: #70bf8b;
    font-size: 20px;
    margin: 0;
}


.section-heading {
    margin-bottom: 20px;
}

.line-shape-white,
.line-shape {
    width: 80px;
    height: 2px;
    background-color: #c1f7a4;
    margin-top: 15px;
}
.line-shape-vertical {
    width: 2px;
    height: 350px;
    background-color: #c1f7a4;
    margin-top: 15px;
}

.line-shape {
    margin-left: calc(50% - 40px);
}

.table {
    display: table;
    height: 100%;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 2;
}

.table-cell {
    display: table-cell;
    vertical-align: middle;
}

#preloader {
    overflow: hidden;
    background-color: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
}

.colorlib-load {
    -webkit-animation: 2000ms linear 0s normal none infinite running colorlib-load;
    animation: 2000ms linear 0s normal none infinite running colorlib-load;
    background: transparent none repeat scroll 0 0;
    border-color: #dddddd #dddddd #70bf8b;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    height: 40px;
    left: calc(50% - 20px);
    position: relative;
    top: calc(50% - 20px);
    width: 40px;
    z-index: 9;
}

@-webkit-keyframes colorlib-load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes colorlib-load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* --------------------------
:: 2.0 Header Area CSS
-------------------------- */

.header_area {
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 99;
    top: 0;
    padding: 0 4%;
}

.menu_area .navbar-brand {
    font-size: 72px;
    font-weight: 700;
    color: #fff;
    margin: 0;
    line-height: 1;
    padding: 0;
    margin-top: -10px;
}


.menu_area .navbar-brand:hover,
.menu_area .navbar-brand:focus {
    color: #fff;
}


.menu_area {
    position: relative;
    z-index: 2;
}

.menu_area #nav .nav-link {
    /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 700;
    border-radius: 30px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    padding-left: 15px;
}


.menu_area nav ul li > a:hover {
    color: #fb397d;
}

.sign-in-button {
    text-align: right;
}

.sign-in-button > a {
    color: #fff;
    font-weight: 700;
    display: inline-block;
    border: 2px solid #ffff;
    height: 40px;
    min-width: 132px !important;
    line-height: 34px !important;
    text-align: center;
    font-size: 17px !important;
    border-radius: 24px 24px 24px 24px;
}

.sign-in-button > a:hover {
    background: #b5fdce;
    color: #fff;
    border-color: transparent;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}


.sign-in-button {
    text-align: right;
}

.sign-in-button > a {
    color: #fff;
    font-weight: 700;
    display: inline-block;
    border: 2px solid #ffff;
    height: 40px;
    min-width: 132px !important;
    line-height: 34px !important;
    text-align: center;
    font-size: 17px !important;
    border-radius: 24px 24px 24px 0px;
}

.sign-in-button > a:hover {
    background: #ffffff;
    color: #70bf8b;
    border-color: transparent;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

/* stcicky css */

.header_area.sticky {
    background-image: linear-gradient(to top right, #70bf8b , #c2edab) !important;
    -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    height: 70px;
    padding-top:15px;
    position: fixed;
    top: 0;
    z-index: 99;
}

.header_area.white {
    background-image: linear-gradient(to top right, #fff , #fff) !important;
}

.header_area.sticky .logo {
    content:url(../img/bg-img/Logo_with_text_primary.png) !important;
}

.header_area.sticky #nav .nav-link {
    color: #70bf8b;
}




.header_area.sticky .sign-in-button > a:hover {
    background: #70bf8b;
    color: #fff;
    border-color: transparent;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.header_area.sticky .sign-in-button > a {
    background: #ffffff;
    color: #70bf8b;
    border-color: transparent;
    border: 2px solid #70bf8b;
}

.header_area.sticky .menu_area .navbar-brand {
    font-size: 50px;
}

.header_area.sticky .menu_area #nav .nav-link {
    /* padding: 23px 15px; */
}
.header_area.sticky .navbar {
    padding: 0;
}


/* --------------------------
:: 3.0 Wellcome Area CSS
-------------------------- */

.wellcome_area {
    background-image: url(../img/bg-img/welcome-bg.jpg);
    height: 100vh;
    position: relative;
    z-index: 1;
    background-position: bottom center;
    background-size: cover;
}

.wellcome_area:after {
    position: absolute;
    width: 100%;
    left: 0;
    height: 2px;
    background-color: #fff;
    content: '';
    bottom: 0;
    z-index: 2;
}

.welcome-thumb {
    width: 45%;
    position: absolute;
    bottom: -130px;
    right: 85px;
    z-index: 9;
}

.welcome-thumb img {
    height: auto;
    width: 100%;
}

.wellcome-heading > h2 {
    font-size: 100px;
    color: #ffffff;
    font-weight: 700;
    position: relative;
    z-index: 3;
}

.get-start-area .email {
    background: #9572e8;
    height: 50px;
    max-width: 260px;
    border: none;
    border-radius: 24px;
    padding: 0px 15px;
}

.form-control::-webkit-input-placeholder {
    color: #cec1f4;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #cec1f4;
    opacity: 1;
}

.form-control::-ms-input-placeholder {
    color: #cec1f4;
    opacity: 1;
}

.form-control::placeholder {
    color: #cec1f4;
    opacity: 1;
}

.get-start-area .email:focus {
    border: none;
    outline-offset: transparent !important;
    border-radius: 30px;
}

.get-start-area .submit {
    background-color: #fb397d;
    color: #fff;
    font-weight: 500;
    display: inline-block;
    border: none;
    height: 50px;
    min-width: 167px;
    line-height: 46px;
    text-align: center;
    border-radius: 24px 24px 24px 0px;
    margin-left: 10px;
}

.get-start-area .submit:hover {
    background: #6f52e5;
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.wellcome-heading > p {
    color: #fff;
}

.wellcome-heading > h3 {
    font-size: 332px;
    position: absolute;
    top: -134px;
    font-weight: 900;
    left: -12px;
    z-index: -1;
    color: #fff;
    opacity: .1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}

.wellcome-heading {
    margin-bottom: 100px;
}

/* --------------------------
:: 4.0 Special Area CSS
-------------------------- */

.single-icon > i {
    font-size: 36px;
    color: #47425d;
}

.single-special > h4 {
    font-size: 22px;
    color: #70bf8b;
}

.single-icon {
    margin-bottom: 25px;
}

.single-special > h4 {
    font-size: 22px;
    color: #70bf8b;
    margin-bottom: 15px;
}

.single-special {
    border: 1px solid #ddf5d0;
    padding: 40px;
    border-radius: 40px 40px 40px 0px;
    -webkit-transition-duration: 800ms;
    -o-transition-duration: 800ms;
    transition-duration: 800ms;
    margin-bottom: 30px;
}


.special_description_area {
    padding-top: 100px;
    padding-bottom: 100px;
    background-image: linear-gradient(to top right, #70bf8b , #c2edab) !important;
}

.special_description_content > h2 {
    color: #70bf8b;
    font-size: 48px;
    margin-bottom: 30px;
}

.single-special:hover {
    -webkit-box-shadow: 0 10px 90px rgba(202, 244, 180, 0.4);
            box-shadow: 0 10px 90px rgba(202, 244, 180, 0.4);
}

.single-special p {
    margin-bottom: 0;
}

.app-download-area {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 1;
    flex-basis: 1;
    margin-top: 50px;
}

.app-download-btn a {
    border: 1px solid #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 30px;
    border-radius: 30px 30px 30px 0;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.app-download-btn.active a,
.app-download-btn a:hover {
    border-color: transparent;
    background-color: #fff;
}

.app-download-btn a i {
    color: #fff;
    font-size: 24px;
    margin-right: 15px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.app-download-btn a p {
    color:white;
    font-size: 16px;
    line-height: 1;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.app-download-btn a p span {
    color:white;
    font-size: 12px;
    display: block;
}

.app-download-btn:first-child {
    margin-right: 20px;
}

.app-download-btn a:hover i,
.app-download-btn.active a i,
.app-download-btn.active a p,
.app-download-btn a:hover p span,
.app-download-btn a:hover p{
    color: #70bf8b;
}

/* --------------------------
:: 5.0 Features Area CSS
-------------------------- */

.single-feature {
    margin-bottom: 50px;
}

.single-feature > p {
    margin-bottom: 0;
}

.single-feature > i {
    color: #fb397d;
    font-size: 30px;
    display: inline-block;
    float: left;
    margin-right: 10px;
}

.single-feature > h5 {
    font-size: 22px;
    color: #5b32b4;
}

.single-feature > p {
    margin-top: 15px;
}

/* --------------------------
:: 6.0 Video Area CSS
-------------------------- */

.video-area {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    border: 20px solid #fff;
    border-radius: 20px;
    height: 650px;
    -webkit-box-shadow: 0 10px 50px rgba(0, 0, 0, 0.08);
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.08);
}

.video-area:after {
    position: absolute;
    content: "";
    top: -20px;
    left: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
    border: 1px solid #f1f4f8;
    z-index: 2;
    border-radius: 20px;
}


.video-area .video-play-btn a {
    width: 80px;
    height: 80px;
    background-color: #70bf8b;
    color: #fff;
    text-align: center;
    display: block;
    font-size: 36px;
    line-height: 80px;
    padding-left: 5px;
    border-radius: 50%;
    top: 50%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 3;
}

.video-area .video-play-btn a:hover {
    background-color: #fff;
    color: #70bf8b;
    border: 1px solid #70bf8b;
}

/* --------------------------
:: 7.0 Cool Facts Area CSS
-------------------------- */

.cool_facts_area {
    position: relative;
    z-index: auto;
    background: #a865e1;
    background: -webkit-linear-gradient(to left, #7873ed, #a865e1);
    background: -webkit-linear-gradient(right, #7873ed, #a865e1);
    background: -webkit-gradient(linear, right top, left top, from(#7873ed), to(#a865e1));
    background: -o-linear-gradient(right, #7873ed, #a865e1);
    background: linear-gradient(to left, #7873ed, #a865e1);
    padding: 180px 0 80px 0;
    top: -100px;
}


.cool_facts_area:after {
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    width: 100%;
    height: 100px;
    background-color: #fff;
    content: ''
}

.cool-facts-content > i {
    color: #ffffff;
    font-size: 36px;
}

.counter-area {
    min-width: 100px;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.counter-area > h3 {
    color: #ffffff;
    font-size: 60px;
    margin-bottom: 0;
    line-height: 1;
}

.cool-facts-content > p {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 0;
}

/* --------------------------
:: 8.0 Screenshots Area CSS
-------------------------- */

.app_screenshots_slides {
    padding: 0 6%;
}

.app_screenshots_slides .single-shot {
    opacity: 0.7;
    max-height: 600px;
    display: inline-block;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    /* -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85) */
}

.app_screenshots_slides .center .single-shot {
    opacity: 1;
    max-height: 500px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.app-screenshots-area .owl-dot {
    height: 11px;
    width: 11px;
    border: 2px solid #a49fba;
    border-radius: 50%;
    display: inline-block;
    margin: 0 6px;
    margin-top: 50px;
}

.app-screenshots-area .owl-dot.active {
    background-color: #fff;
    border: transparent;
}

.app-screenshots-area .owl-dots {
    height: 100%;
    width: 100%;
    text-align: center;
}

/* --------------------------
:: 9.0 Pricing Plan Area CSS
-------------------------- */

.pricing-plane-area {
    background: #f5f9fc;
}

.single-price-plan {
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 30px 30px 30px 0;
    margin-bottom: 30px;
}

.single-price-plan .package-plan {
    padding: 40px 0;
    border-radius: 30px 30px 0 0;
}

.single-price-plan .package-plan > h5 {
    color: #5b32b4;
    font-size: 18px;
}


.single-price-plan .package-plan .ca-price h4 {
    color: #fb397d;
    font-size: 48px;
    position: relative;
    z-index: 1;
    margin-bottom: 0;
    line-height: 1;
}

.single-price-plan .package-plan .ca-price span {
    font-size: 18px;
    color: #fb397d;
}

.single-price-plan .plan-button > a {
    background: #726a84;
    padding: 10px 0;
    border-bottom-right-radius: 30px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    display: block;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.single-price-plan .package-description > p {
    margin-bottom: 0;
    line-height: 1;
    padding: 15px 0;
    border-bottom: 1px solid #ebebeb;
}

.single-price-plan .package-description > p:first-child {
    border-top: 1px solid #ebebeb;
}

.single-price-plan.active .package-plan,
.single-price-plan.active .plan-button > a,
.single-price-plan:hover .plan-button > a {
    background-color: #a865e1;
}

.single-price-plan.active .package-plan .ca-price > h4,
.single-price-plan.active .package-plan .ca-price > span,
.single-price-plan.active .package-plan > h5,
.single-price-plan.active .plan-button > a {
    color: #fff;
}

/* --------------------------
:: 10.0 Testimonials Area CSS
-------------------------- */

.clients-feedback-area .client-name > h5 {
    color: #5b32b4;
    font-size: 22px;
    margin-top: 5px;
}

.clients-feedback-area .star-icon > i {
    font-size: 18px;
    color: #f1b922;
}

.clients-feedback-area .client-description > p {
    max-width: 1010px;
    margin: auto;
    margin-bottom: 15px;
    font-size: 22px;
}

.clients-feedback-area .client > i {
    font-size: 48px;
    color: #897ceb;
    margin-bottom: 30px;
}

.clients-feedback-area .client-name > p {
    color: #b5aec4;
}

.clients-feedback-area .slick-dots {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.clients-feedback-area .slick-dots li button {
    width: 12px;
    height: 12px;
    text-indent: -999999999999999999999999px;
    border: 3px solid #a49fba;
    background-color: #fff;
    border-radius: 50%;
    margin: 5.5px;
    outline: none;
}

.clients-feedback-area .slick-dots li.slick-active button {
    background-color: #fb397d;
    border-color: #fb397d;
}

.clients-feedback-area .slick-slide.client-thumbnail {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 129px;
}


.clients-feedback-area .slick-slide img {
    display: block;
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
    border: 5px solid #fff;
    -webkit-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.clients-feedback-area .slick-slide.slick-current img {
    width: 100px !important;
    height: 100px !important;
}

.clients-feedback-area .slick-arrow {
    position: absolute;
    width: 30px;
    background-color: #fff;
    height: 100%;
    text-align: center;
    font-size: 24px;
    color: #b5aec4;
    display: block;
    line-height: 550%;
    left: 0;
    z-index: 90;
    cursor: pointer;
}

.clients-feedback-area .slick-arrow.fa-angle-right {
    left: auto;
    right: 0;
    top: 0;
}

/* --------------------------
:: 11.0 CTA Area CSS Start
-------------------------- */

.our-monthly-membership {
    background: #a865e1;
    background: -webkit-linear-gradient(to left, #7873ed, #a865e1);
    background: -webkit-linear-gradient(right, #7873ed, #a865e1);
    background: -webkit-gradient(linear, right top, left top, from(#7873ed), to(#a865e1));
    background: -o-linear-gradient(right, #7873ed, #a865e1);
    background: linear-gradient(to left, #7873ed, #a865e1);
}

.membership-description > h2 {
    color: #ffffff;
    font-size: 36px;
}

.membership-description > p {
    color: #ffffff;
    margin-bottom: 0;
}

.get-started-button > a {
    background: #ffffff;
    height: 45px;
    min-width: 155px;
    display: inline-block;
    text-align: center;
    line-height: 45px;
    color: #fb397d;
    font-weight: 500;
    border-radius: 23px 23px 23px 0;
}

.get-started-button > a:hover {
    background: #5b32b4;
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.get-started-button {
    text-align: right;
}

/* --------------------------
:: 12.0 Team Area CSS Start
-------------------------- */

.member-image {
    margin-bottom: 30px;
    border-radius: 30px 30px 30px 0;
    position: relative;
    z-index: 1;
}

.member-image img {
    border-radius: 30px 30px 30px 0;
}

.member-text > h4 {
    font-size: 22px;
    color: #5b32b4;
}

.member-text > p {
    color: #b5aec4;
    margin-bottom: 0;
}

.team-hover-effects {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(105, 75, 228, 0.8);
    border-radius: 30px 30px 30px 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;
}

.single-team-member:hover .team-hover-effects {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.team-social-icon {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.team-social-icon > a {
    font-size: 14px;
    color: #fff;
    background: #9a81d7;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 10px 10px 10px 0;
    display: block;
    margin: 2.5px;
}

.single-team-member {
    margin-bottom: 50px;
}

/* --------------------------
:: 13.0 Contact Area CSS Start
-------------------------- */

.address-text > p,
.phone-text > p,
.email-text > p {
    font-size: 18px;
    margin-bottom: 10px;
}

.address-text > p > span,
.phone-text > p > span,
.email-text > p > span {
    color: #32b473;
}

.footer-contact-area .line-shape {
    margin-left: 0;
}

.footer-contact-area .heading-text > p {
    margin-top: 50px;
}

.footer-contact-area .heading-text {
    margin-bottom: 50px;
}

#name {
    font-size: 18px;
    padding: 15px 15px;
    border-radius: 25px 25px 25px 0;
}

#email {
    font-size: 18px;
    padding: 15px 15px;
    border-radius: 25px 25px 25px 0;
}

#message {
    border-radius: 25px 25px 25px 0;
}

.btn.submit-btn {
    background: #70bf8b;
    height: 48px;
    min-width: 185px;
    color: #ffffff;
    font-weight: 500;
    border-radius: 22px 25px 22px 0;
}

.btn.submit-btn:hover {
    background: #5b32b4;
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.footer-contact-area {
    background: #f5f9fc;
}
.footer-area{
  background: #fff;
}

.footer-text > p {
    margin-bottom: 20px;
}

.footer-head{
    font-size:30px;
}

/* --------------------------
:: 14.0 Footer Area CSS Start
-------------------------- */

.footer-text > h2 {
    color: #5b32b4;
    font-size: 72px;
    font-weight: 700;
}

.footer-social-icon > a > i {
    background: #9b69e4;
    font-size: 14px;
    color: #ffffff;
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-weight: 500;
    border-radius: 10px 10px 10px 0px;
    margin-right: 10px;
}

.footer-social-icon > a > i:hover {
    background: #fb397d;
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
}

.social-icon {
    margin-bottom: 30px;
}

.footer-text {
    margin-bottom: 15px;
    text-align:justify;
}

.footer-menu ul li {
    display: inline-block;
}

.footer-menu ul li a {
    border-right: 2px solid #726a84;
    display: block;
    padding: 0 7.5px;
    color: #726a84;
}

.footer-social-icon > a .active {
    background: #fb397d;
}

.footer-menu ul li:last-child a {
    border-right: 0 solid #ddd;
}

.footer-social-icon {
    margin-bottom: 30px;
}

.footer-menu {
    margin-bottom: 15px;
}

.copyright-text > p {
    margin-bottom: 0;
}


.footer-distributed{
	background-color:#181B20;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	font: bold 14px Nunito;
    text-align: justify;
    display: flex;
    justify-content: center;
    padding: 30px 30px 30px 30px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display: inline-block;
	vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left{
    width: 324px;
    color: #ffffff;
    margin-right: 20px;
}

/* The company logo */

.footer-distributed h3{
	color:  #000;
	font: normal 36px 'Cookie', cursive;
	margin: 0;
}

.footer-distributed h3 span{
	color:  #5383d3;
}

/* Footer links */

.footer-distributed .footer-links{
	color:  #000;
	margin: 20px 0 12px;
	padding: 0;
}

.footer-distributed .footer-links a{
	display:inline-block;
	line-height: 1.8;
	text-decoration: none;
	color:  inherit;
}

.footer-distributed .footer-company-name{
	color:  #000;
	font-size: 14px;
	font-weight: normal;
	margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center{
	width: 25%;
}

.footer-distributed .footer-center i{
	background-color:  #33383b;
	color: #fff;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	line-height: 42px;
	margin: 10px 15px;
    vertical-align: middle;
    text-align :center
}

.footer-distributed .footer-center i.fa-envelope{
	font-size: 14px;
	line-height: 38px;
}

.footer-distributed .footer-center p{
	display: inline-block;
	color: #000;
	vertical-align: middle;
	margin:0;
}

.footer-distributed .footer-center p span{
	display:block;
	font-weight: normal;
	font-size:14px;
	line-height:2;
}

.footer-distributed .footer-center p a{
	color:  #5383d3;
	text-decoration: none;;
}


/* Footer Right */

.footer-distributed .footer-right{
    width: 30%;
}

.footer-distributed .footer-company-about{
	line-height: 20px;
	color:  #000;
	font-size: 14px;
	font-weight: normal;
	margin: 0;
}

.footer-distributed .footer-company-about span{
	display: block;
	color:  #000;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

.footer-distributed .footer-icons{
	margin-top: 25px;
}

.footer-distributed .footer-icons a{
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color:  #33383b;
	border-radius: 2px;

	font-size: 20px;
	color: #fff;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}
.forvideo{
    border: 4px;
    padding: 5px; 
    min-width: 50%;
    height:300px;
}


.styleOvervireDetail{
    text-align: left !important;
}


.stylePaddingBottom{
    padding-bottom: 20px !important;
}

.styleMarginEmo{
    margin-top: 35px !important;
}

.styleBackgroundOverview {
    padding: 25px 25px !important;
    text-align: center;
    margin-bottom: 15px;
    background-color: #F9F9F9 !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.styleMarginAddress {
    text-align: justify;
    margin-top: 15px !important; 
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 1500px) {

	.footer-distributed{
        font: bold 14px Nunito;
        padding: 35px 30px;
        padding-bottom: 0px;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
        margin-bottom: 40px;
        justify-content: center
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
    }
    .forvideo{
        width: 30%;
        height: 400px;
    }
    .HeaderPage{
        justify-content: center
    }
}
@media (max-width: 1000px) {

	.footer-distributed{
        font: bold 14px Nunito;
        padding: 35px 30px;
       
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
        margin-bottom: 40px;
        justify-content: center
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
    }
    .forvideo{
        width: 30%;
        height: 400px;
    }
    .HeaderPage{
        justify-content: center
    }
    .contentnopad{
        width: 100% !important;
    }
    .tagmanufactdes{
        margin-top: 0px !important;
    }
    .tagmanufactdesbc{
        margin-top: 0px !important;
    }
    
}


/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 500px) {

	.footer-distributed{
        font: bold 14px Nunito;
        flex-flow: column;
        padding: 25px 4px !important;
    }
    .videotag{
        margin-left: 0px !important;
        font-size: 23px !important;
    }

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
        margin-bottom: 40px;
        justify-content: center
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
	}
    .forvideo{
        width: 100%;
        height: 300px;
    }
    .searchButton{
        width: 80px !important;
    }
   .searchButtonInside{
    width: 80px !important;
   }
   .iconhv{
       height: 50px !important;
       width: 50px !important;
   }
   .iconhvEdu{
        height: 50px !important;
        width: 50px !important;
    }
   .cooName {
       font-size: 14px !important;
   }
   .cooAdd{
       font-size: 13px !important;
       text-align: initial;
   }
   .flexrowharvest{
    flex-flow: row;
    display: flex; 
    /* justify-content: center; */
    }
    .pructName{
        font-size: 22px !important;
    }
    .manufactsec{
        flex-flow: column !important;
        /* padding: 0px 10px !important; */
    }
    .desmanufact{
    justify-content: flex-start !important;
    text-align: start;
    }
    .LineBreakDesManu{
    display: block !important;
    }
    .LineBreakDesWide{
        display: none !important;
    }
    .tagmanufactdes{
        margin-left: 0px !important;
        text-align: justify !important;
        /* margin-top: 15px !important; */
    }
    .tagmanufactdesbc{
        margin-left: 0px !important;
        text-align: justify;
        margin-top: 15px !important;
    }
    .diarycontent{
        display: flex;
        flex-flow: column;
    }
    .textFeedBack{
        font-size: 20px important;
        font-family: 'Nunito', sans-serif;
        font-weight: 400;
    }
    .tablayout{
        padding: 15px 0px !important;
    }
    .manufactlayout{
        box-shadow: none !important;
    }
    .xuatxutag{
        font-size: 22px !important;
        margin-top: 20px !important;
        margin-left: 17px !important;
    }
    .sectionone{
        width: 100% !important;
    }
    .sectiontwo{
        width: 100% !important;
        text-align: start !important;
    }
    .LineBreakDown{
        /* width: 100% !important; */
        width: 0% !important;
        margin-top: -12px;
    }
    .tagmanufactdestimeline{
        margin-right: 20px !important;
    }
    .videocolapse{
        margin-top: 29px !important;
        margin-left: 27% !important;
    }
    .videocolapseMap{
        margin-top: 29px !important;
        margin-left: 27% !important;
    }
    .imagetoview{
        height: 60px !important;
    }
}
.imagetoview{
    height: 100px;
    border-radius: 8px;
    margin-left: 5px;
}
.videocolapse{
    margin-top: 9px;
    margin-left: 68%;
    height: 10px;
}
.videocolapseMap{
    margin-top: 9px;
    margin-left: 71%;
    height: 10px;
}
button:focus {outline:0 !important;}
.sectionone{
    width: 30%;
}
.sectiontwo{
    width: 70%;
    text-align: end;
}
.diarycontent{
    margin-left: 0px !important;
}
.diarycontentdetail{
    margin-left: 0px !important;
    flex-flow: column;
}
.desmanufact{
   display: flex;
   justify-content: flex-end;
   text-align: justify;
   height: 36px;
}
.flexrowharvest{
      flex-flow: row !important;
      display: flex !important; 
      margin-bottom: 20px !important;
}
.manufactsec{
    margin-top: 20px !important;
    flex-flow: row;
    padding: 0px 25px;
}
.phonenumber{
    height: 23px;		color: #FFFFFF;	font-family: Nunito;	font-size: 12px;	font-weight: bold;	line-height: 16px;  margin-bottom: 0px;
}
.addressvbc{
    height: 46px;		color: #FFFFFF;	font-family: Nunito;	font-size: 12px;	line-height: 16px; margin-bottom: 0px;
}
.introduce{
	color: #FFFFFF;	font-family: Nunito;	font-size: 12px;	line-height: 16px;
}
.Company{
    height: 19px;	width: 77px;	color: #FFFFFF;	font-family: Nunito;	font-size: 20px;	font-weight: bold;	line-height: 19px;
}
.oursettler{
    height: 19px;	width: 137px;	color: #FFFFFF;	font-family: Nunito;	font-size: 16px;	font-weight: bold;	line-height: 19px;
}
.LineBreak{
    height: 2px;	width: 50px;	background-color: #4FB779;
}
.weblink{
    height: 19px;	color: #FFFFFF;	font-family: Nunito;	font-size: 12px;	font-weight: 500;	line-height: 16px;
}
.wrapperFooter{
    flex-grow: 1;
    display: inline-block;
}
.textleft{
    height: 42px;	width: 285px;	color: #E8E9E9;	font-family: Nunito;	font-size: 16px;	line-height: 23px;
}
.EmailInput{
    height: 44px;	width: 285px;}
.logoAgri{
    min-width: 200px;
    max-height:55px;
}
.styleCardProduct{
    height: 280px;
    border-radius: 25px;
}
.styleImageOfProduct{
    max-height: 150px;
}
.breakarea{
    background-color:#181B20;
    text-align: center;
    align-items: center;
    width: 100%;
}
.LineBreakDown{
    height: 1px;	width: 80%;	transform: scaleY(-1);	background-color: #282B30;
}
.Copyright{
    height: 23px;	width: 275px;	color: #FFFFFF;	font-family: Nunito;	font-size: 15px;	line-height: 23px;	text-align: center;
}
.notchedOutline{
    border-color: yellow
}
.HeaderPage{
	background-color: #FFFFFF; 
    display: flex;
    justify-content: center;
    padding: 20px 20px !important;
}
.vbccontent{
    /* text-Align: "center", padding:"0px 0px !important"; */
    text-align: center;
    padding: 0px 0px !important;
}
.logoAgriHeader{
    max-height: 70px; min-height: 25px; margin-left: 0px;
}
.logoVBCHeader{
    max-height: 70px; min-height: 25px; margin-right: 0px
}
.languageImg {
    min-height:15px;
    max-height: 24px;
  }
.poslang{
    display: flex;
    justify-content: flex-end;
    text-align: flex-end;
    padding: 0px 0px !important;
}
#imageThumbs  {
    float:left;
    width:100%;
    height:75px;
    display: flex;
    justify-content: center;
  }
#imageThumbs li:hover  {
    cursor:pointer;
  }
.active  {
    border:1px solid #959595 !important;
    background-color: white !important;
    border-radius: 4px;
}
ul#thumbs  {
    list-style:none;
    display:inline;
    padding:0;
    /* margin:10px 0 50px 50px; */
  }
  ul#thumbs li  {
    display:inline-block;
    margin:0 10px 0 0;
    border:1px solid #E8E8E8;
    padding:0;
    height:75px;
    width:75px;
  }
.vbcpost{
    display: flex;
    justify-content: center;
    text-align: center;
}
.headerBar{
    background-color: #4FB779;
    padding: 15px 0px;
    display: flex;
    justify-content: center;
}
.qrButton{
    margin-left: 10px;
    min-height: 50px;	min-width: 50px;	border-radius: 2.5px;	background-color: #5AC585;	box-shadow: 0 1px 0 0 rgba(255,255,255,0.03); display: flex; justify-content: center; align-items: center
}
.inputForm{
    height: 50px;	width: 100% !important;	border-radius: 3px;	background-color: #5AC585;	box-shadow: 0 1px 0 0 rgba(255,255,255,0.03);
}
.searchButton{
    height: 44px;	width: 165px;	border: 2px solid #FFFFFF; margin-left: 10px;  display: flex; justify-content: center; align-items: center; margin-top: 3px
}
.searchButtonInside{
    color: #ffffff !important; font-Weight:700 !important; font-size: 13px ;height: 44px;	width: 165px
}
.pructName{
   color: #212B36;	font-family: 'Nunito', sans-serif;	font-size: 28px !important;	font-weight: 600;	line-height: 32px;
   
}
.cardbodyov{
    padding-Top:10px; padding-Bottom:10px;
}
.iconhv{
    height: 60px;	width: 60px;	background-color: #4FB779; border-radius: 50%; display: flex;justify-content: center; align-items: center;
}
.iconhvEdu{
    height: 60px;	width: 60px;	background-color: #1047F7; border-radius: 50%; display: flex;justify-content: center; align-items: center;
}

.cooInfo{
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
    align-items: flex-start;
    padding: 10px 10px;
}
.cooName{
   color: #212B36;	font-family: 'Nunito', sans-serif;	font-size: 16px;	font-weight: 600;	line-height: 24px; margin-top: -5px; word-spacing: 0px;
}
.cooAdd{
   color: #637381;	font-family: 'Nunito', sans-serif;	font-size: 14px;	line-height: 20px; margin-top: -12px
}
.imageFind{
    height: 20px !important;
    width: 20px !important;
    margin-left: 10px;
    margin-top: 2px;
}
.btnfb{
    height: 36px;	width: 146px;	border: 1px solid #C4CDD5 !important;	border-radius: 3px;	box-shadow: 0 1px 0 0 rgba(22,29,37,0.05) !important;
    color: #000 !important; font-size: 13px !important; background-color: #fff; font-family: 'Nunito', sans-serif;
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
}
.btnfb:hover{
    color: #ffffff !important;
    height: 36px;	width: 146px;	border-radius: 3px;	background-color: #4FB779 !important;
    background: none;
}
/* .btnfb:focus{
    color: #ffffff !important;
    height: 36px;	width: 146px;	border-radius: 3px;	background-color: #4FB779 !important;
    background:none;
} */

.btnfbEdu{
    height: 36px;	width: 146px;	border: 1px solid #C4CDD5 !important;	border-radius: 3px;	box-shadow: 0 1px 0 0 rgba(22,29,37,0.05) !important;
    color: #000 !important; font-size: 13px !important; background-color: #fff; font-family: 'Nunito', sans-serif;
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
}

.btnfbEdu:hover{
    color: #ffffff !important;
    height: 36px;	width: 146px;	border-radius: 3px;	background-color: #1047F7 !important;
    background: none;
}

.btnfbEdu:focus{
    color: #ffffff !important;
    height: 36px;	width: 146px;	border-radius: 3px;	background-color: #1047F7 !important;
    background:none;
}

.textdesTx{
    color: #007ACE  !important;		font-size: 14px;	line-height: 20px; font-family: 'Nunito', sans-serif !important;
}
.textdes{
    color: #212B36;		font-size: 14px;	line-height: 20px; font-family: 'Nunito', sans-serif !important;
}
.detailtitle{
    color: #212B36;		font-size: 16px;	font-weight: 600;	line-height: 24px;  font-family: 'Nunito', sans-serif !important;
}
.LineBreakDes{
    height: 1px !important;	width: 100%;	transform: scaleY(-1);	background-color: #DFE3E8; opacity: 0.2;
}
.LineBreakDesCert{
    height: 1px !important;	width: 80%;	transform: scaleY(-1);	background-color: #DFE3E8; opacity: 0.2;
}
.LineBreakDesWide{
    height: 1px !important;	width: 100%;	transform: scaleY(-1);	background-color: #DFE3E8; opacity: 0.2;
}
.LineBreakDesManu{
    max-height: 1px !important;	width: 100%;	transform: scaleY(-1);	background-color: #DFE3E8; display: none;  opacity: 0.2;
}
.manufactlayout{
    border-radius: 3px;	background-color: #FFFFFF; 	box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15); padding: 0px 0px !important;
}
.xuatxutag{
    color: #212B36 !important;	font-size: 23px !important;	font-weight: 600 !important;	line-height: 30px !important; margin-top: 30px; margin-left: 25px;
    font-family: 'Nunito', sans-serif !important;
}
.tagmanufact{
    font-size: 15px !important;	font-weight: 600 !important;	line-height: 20px; color: #212B36; font-family: 'Nunito', sans-serif!important;
    text-align: left;
}

.tagmanufactupdated{
    font-size: 15px !important;	font-weight: 600 !important;	line-height: 20px; color: #212B36; font-family: 'Nunito', sans-serif!important;
}

.tagmanufactdes{
    font-size: 15px !important;	line-height: 20px; color: #212B36; font-weight: 400 !important; 
    /* white-space: nowrap;        
    overflow: hidden; */
    text-overflow: ellipsis;
    font-family: 'Nunito', sans-serif !important;
    margin-left: 15px;
    text-align: end;
}
.tagmanufactdesdetail{
    font-size: 15px !important;	line-height: 20px; color: #212B36; font-weight: 400 !important; 
    /* white-space: nowrap;        
    overflow: hidden; */
    text-overflow: ellipsis;
    font-family: 'Nunito', sans-serif !important;
    margin-left: 0px;
    text-align: start;
    word-break: break-word;
}
.tagmanufactdestimeline{
    font-size: 15px !important;	line-height: 20px; color: #007ACE !important; font-weight: 400 !important; 
    
    font-family: 'Nunito', sans-serif !important;
    margin-top: 0px;
    text-decoration: underline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width:100%; 
}
.tagmanufactdesbc{
    font-size: 15px !important;	line-height: 20px; color: #007ACE !important; font-weight: 400 !important;
    white-space: nowrap;        
    overflow: hidden; 
    font-family: 'Nunito', sans-serif !important;
    word-wrap: break-word;
    text-overflow: ellipsis;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    text-decoration: underline;
}
.flexcenter{
    display: flex; justify-content: center
}
.flexend{
    display: flex;justify-content: flex-end
}
.tagAction{
    color: #212B36;	font-family: "SF Pro Text";	font-size: 15px;	font-weight: 600;	line-height: 16px;
}
.flexvideo{
    display: flex;
    justify-content: center;
    flex-flow: column;
}
.contentnopad{
    padding: 0px 0px !important;
    width: 919px;
}
.contentparent{
    background-color: #F9F9F9;
    padding: 0px 0px !important;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
}
.tablayout{
    padding: 15px 20px;
}
.titleExtension{
   	color: #212B36;	font-family: 'Nunito', sans-serif;	font-size: 26px;	font-weight: bold;	line-height: 32px;
}
.desExtension{
	color: #637381;	font-family: 'Nunito', sans-serif;	font-size: 20px;	line-height: 28px;	text-align: center;
}

.imageSlider{
    /* width: 400px !important; */
    width: auto !important;
    height:400px;
}

.buttonOfTab{

    touch-action: manipulation;
    border-radius: 7px;
    /* text-transform: uppercase; */
    /* letter-spacing: 0; */
    /* vertical-align: middle; */
    background-color: transparent;
    border: none;
    width: 70px;
    height: 70px;
    background-color: #e4e3e3ba;
}

.buttonOfTab:hover{

    touch-action: manipulation;
    border-radius: 7px;
    /* text-transform: uppercase; */
    /* letter-spacing: 0; */
    /* vertical-align: middle; */
    background-color: transparent;
    border: none;
    width: 70px;
    height: 70px;
    background-color: #e6e6e6ba;
    transform: scale(1.1);
    transition: all ease-in-out 0.5s;
}
.imageOfTab{
    height: 45px;
    padding: 10px 10px
}


.marginOfTab{
    margin-left: 26%;
    display: flex;
    justify-content: center;
    /* margin-top: -18px; */
}

.styleNoteUser{
    display: flex;
    margin-bottom: 14px;
    font-size: 11px;
    color: #666d67;
}
@media only screen and (min-width : 768px) and (max-width : 1024px){
    .marginOfTab{
        margin-left: 10%;
        display: flex;
        justify-content: center;
    }
    .styleNoteUser{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 14px;
        font-size: 11px;
        color: #666d67;
        margin-right: 50px !important;
    }
}

@media (max-width: 500px){
    .marginOfTab{
        margin-left: -3.5px;
        display: flex;
        justify-content: center;
    }
    .styleNoteUser{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 14px;
        font-size: 10px;
        color: #666d67;
        margin-right: 8px !important;
    }
  }


  

.widthAvatar{
    width: 67%
}

.widthCert{
    width: 33%;
    text-align: right;
}



@media (max-width: 500px){
    .widthAvatar{
        width: 100%
    }
    
    .widthCert{
        width: 100%;
        text-align: left
    }

    .imageSlider{
        width: auto !important;
        height: 200px;
        /* margin-right: 5px;
        margin-left: 12px; */
    }
  }

.jss454 {
    padding: 25px 25px !important;
    text-align: center;
    margin-bottom: 20px;
    background-color: #F9F9F9;
    width: 100%;
}

.pictureAvatar {
    width: 100%;
}

.pictureAvatar img {
    background: #fff;
}

@media (max-width: 500px){
    .pictureAvatar {
        margin-left: 18px;
        width: 100%;
    }

}

.descriptionCor {
    margin-top: 12px;
}

@media (max-width: 500px){
    .descriptionCor {
        margin-left: 18px;
    }
}

.tagPP{
    color: #212B36 !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    font-family: 'Nunito', sans-serif !important;
}

@media (max-width: 500px){
    .errorGridDes{
        margin-left: -15px;
        color: #212B36 !important;
        font-size: 23px !important;
        font-weight: 600 !important;
        line-height: 24px !important;
        margin-top: 30px;
        margin-left: 25px;
        font-family: 'Nunito', sans-serif !important;
    }
    .errorGrid{
        margin-left: 17px !important;
    }
}

.ppErr{
    
}

.cardErr{

}
@media (max-width: 500px){
    /* .ppErr{
        margin-left: 16px
    } */
    .cardErr{
        margin-bottom: -6px !important;
    }
}

.buttonErrMargin{
    margin-right: 30px;
}

.styleXX{
    margin-left: 3px;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
    margin-top: 15px;
}

.style3D{
    width: 700px;
    height: 371px
}



@media (max-width: 500px){
    .buttonErrMargin{
        margin-right: 1px !important
    }
    .styleXX{
        margin-left: -5px;
        display: flex;
        justify-content: center;
        margin-bottom: 3%;
        margin-top: 15px;
    }
    .style3D{
        width: 380px;
        height: 371;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.styleLogoAGD{
    margin-left: 11%;
}

.styleCardProduct{
    height: 315px;
}

.styleImageProduct{
    /* background-color: #eeeff1; */
    height: 158px;
    transition: transform .5s ease;
}
.styleImageProduct2{
        /* background-color: #eeeff1; */
        height: 130px;
        -webkit-transition: -webkit-transform .5s ease;
        transition: -webkit-transform .5s ease;
        transition: transform .5s ease;
        transition: transform .5s ease, -webkit-transform .5s ease;
        width: 222px;
        /* display: flex; */
        /* justify-content: center; */
        margin-left: 92px;
        margin-top: 57px;
    
}

.styleImageProduct:hover{
    /* background-color: #eeeff1; */
    -webkit-transform:scale(1.2); transform:scale(1.2);
}


.styleNameProduct{
    font-weight: 900;
    color: #191919;
    font-size: 20px;
    font-family: Nunito;
}

.styleCorProduct{
    font-size: 12px;
    width: 207px;
}

.styleCenterCor{
    display: flex;
    justify-content: center;
    height: 48px;
}

.footer-distributed{
	background-color:#181B20;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	font: bold 14px Nunito;
    text-align: justify;
    display: flex;
    justify-content: center;
    padding: 30px 30px 30px 30px;
    
}

.logoAgri{
    min-width: 200px;
    max-height:55px;
}

.introduce{
	color: #FFFFFF;	font-family: Nunito;	font-size: 12px;	line-height: 16px;
}

.phonenumber{
    height: 23px;		color: #FFFFFF;	font-family: Nunito;	font-size: 12px;	font-weight: bold;	line-height: 16px;  margin-bottom: 0px;
}

.addressvbc{
    height: 46px;		color: #FFFFFF;	font-family: Nunito;	font-size: 12px;	line-height: 16px; margin-bottom: 0px;
}

.weblink{
    height: 19px;	color: #FFFFFF;	font-family: Nunito;	font-size: 12px;	font-weight: 500;	line-height: 16px;
}

.breakarea{
    background-color:#181B20;
    text-align: center;
    align-items: center;
    width: 100%;
}

.styleLogoProduct{
    width: 174px;
}

.styleImageOfProduct{
    height: 144px;
}

.styleButtonSm{
    /* width: 65px; */
    margin-left: 0px !important;
}

.styleSearchProduct{

}

.styleQRCodeProduct{
    
}

.styleHotlineProduct{
    color: white;
    font-weight: 400;
    font-size: 15px; 
    margin-left: -29px;
}

.styleLangaueProduct{

}

.styleContactProduct{
    color: white;
    /* margin-left: -152px; */
    font-weight: 400;
    font-size: 15px; 
}

.HeightInfoProduct {
    height: 85px;
}

.styleSearchID{
    width:100%;
}
@media (max-width: 2000px){
    .el-wrapper{
        width: 443px !important;
    } 
}

@media (max-width: 1460px){
    .el-wrapper{
        width: 325px !important;
    }
    .styleHotlineProduct{
        color: white;
        margin-left: -24px;
    }

    .styleContactProduct{
        color: white;
        margin-left: -43px;
    }

    .styleImageOfProduct{
        height: 120px;
    }
    
    .styleButtonSm {
        width: 73px;
        margin-left: 0px !important;
    }

    .HeightInfoProduct {
        height: 77px;
    }

    .styleLangaueProduct{
        margin-left: 121px !important
    }

    
    .styleCardProduct{
        height: 299px;
    }

    .styleImageProduct{
        /* background-color: #eeeff1; */
        height: 149px;
        transition: transform .5s ease;
    }

    .styleImageProduct:hover{
        /* background-color: #eeeff1; */
        -webkit-transform:scale(1.2); transform:scale(1.2);
    }

    .styleImageProduct2 {
        /* background-color: #eeeff1; */
        height: 124px;
        -webkit-transition: -webkit-transform .5s ease;
        transition: -webkit-transform .5s ease;
        transition: transform .5s ease;
        transition: transform .5s ease, -webkit-transform .5s ease;
        margin-left: 31px;
        margin-top: 52px;
        width: 233px;
    }



    .styleNameProduct{
        font-weight: 900;
        color: #191919;
        font-size: 13px;
        font-family: Nunito;
    }

    .styleCorProduct{
        font-size: 10px;
        width: 207px;
    }

    .styleCenterCor{
        display: flex;
        justify-content: center;
        height: 48px;
    }
}

@media only screen and (min-width : 768px) and (max-width : 1024px)  { 
    .styleSearchProduct{
        margin-left: -25px !important;
        width: 183px !important;
    }
    .styleQRCodeProduct{
        margin-left: -70px;
        margin-right: 38px;
    }

    
    .HeightInfoProduct {
        height: 74px;
    }
    .inputForm{
        height: 30px;
        /* width: 80%; */
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0 1px 0 0 rgba(255,255,255,0.03);
        border: 1.5px solid #17a349;
    }

    .qrButton{
        min-height: 30px;	min-width: 30px;
        height: 30px;
    }

    .qrImage{
        height: 20px;
    }

    .searchButton {
        height: 27px;
        margin-left: 11px;
        margin-top: 1px;
        width: 91px;
    }

    .styleHotlineProduct{
        font-size: 13px;
        color: white;
        margin-left: 0px !important;
        margin-top: 4px;
        margin-right: -3px;
    }

    .styleButtonSm{
        width: 80px;
        margin-left: 10px !important;
    }

    .styleLangaueProduct{
        margin-left: -90px !important;
    }

    .styleContactProduct{
        font-size: 13px;
        color: white;
        margin-top: 4px;
        margin-left: 0px !important;
    }

    .styleCardProduct {
        height: 299px;
        margin-left: 6px;
    }

    .styleImageProduct2 {
        /* background-color: #eeeff1; */
        height: 124px;
        -webkit-transition: -webkit-transform .5s ease;
        transition: -webkit-transform .5s ease;
        transition: transform .5s ease;
        transition: transform .5s ease, -webkit-transform .5s ease;
        margin-left: 17px;
        margin-top: 78px;
        width: 196px;
        margin-left: 59px;
    }

    .inputForm div div input{
        color: #2f9c58 !important;
        margin-top: -5px;
    }
}


@media (max-width: 500px){
    .el-wrapper{
        width: 100% !important;
        margin: 3px auto !important;
    } 
    /* .el-wrapper:focus .info-inner {
        bottom: 100px !important;
      }
    .info-inner{
        bottom:4px !important;
    } */
    .img{
        height: 150px !important;
    }
    .box-down{
        height: 35px !important;
    }
    .p-name{
        font-size: 12px !important;
    }
    .p-company{
        font-size: 10px !important;
    }
    .marginLeftCard{
        margin-left: 14px !important;
    }
    .box-up{
        height: 240px !important;
    }
    .styleImageOfProduct{
        height: 80px;
    }

    .styleCorProduct{
        font-size: 8px;
        width: 207px;
    }

    .styleImageProduct{
        /* background-color: #eeeff1; */
        height: 85px;
        transition: transform .5s ease;
    }
    .styleImageProduct2 {
        /* background-color: #eeeff1; */
        height: 61px;
        -webkit-transition: -webkit-transform .5s ease;
        transition: -webkit-transform .5s ease;
        transition: transform .5s ease;
        transition: transform .5s ease, -webkit-transform .5s ease;
        width: 132px;
        margin-left: 5px;
        margin-top: 65px;
    }
    .styleSearchProduct{
        margin-left: -8px !important;
        width: 160px !important;    
    }
    .styleQRCodeProduct{
        margin-left: -16px;
    }

    .inputForm{
        height: 30px;
        /* width: 80%; */
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0 1px 0 0 rgba(255,255,255,0.03);
        border: 1.5px solid #17a349;
    }

    .qrButton{
        min-height: 30px;	min-width: 30px;
        height: 30px;
    }

    .qrImage{
        height: 20px;
    }

    .searchButton {
        height: 28px;
        margin-left: 10px;
        margin-top: 1px;
    }

    .styleHotlineProduct{
        font-size: 10px;
        color: white;
        margin-left: 0px !important;
        margin-top: 4px;
        margin-right: -3px;
    }

    .styleButtonSm{
        width: 54px;
        margin-left: -11px !important;
        height: 20px;
        font-size: 10px !important;
    }

    .styleLangaueProduct{
        margin-left: 0px !important;
    }

    .styleContactProduct{
        font-size: 10px;
        color: white;
        margin-top: 4px;
        margin-left: 0px !important;
    }

    .styleNameProduct{
        font-weight: 900;
        color: #191919;
        font-size: 7px;
        font-family: Nunito;
    }

    .styleCardProduct{
        height: 200px;
        margin-left: 6px;
    }
    
    .styleCenterCor{
        display: flex;
        justify-content: center;
        height: 34px;
    }

    .MuiButton-label-161{
        font-size: 7px;
    }

    .HeightInfoProduct{
        height: 50px;
    }

    .styleButtonSm span{
        font-size: 7px;
    }

    .styleSearchID div{
        padding-top: 4px !important;
    }

    .inputForm div div input{
        color: #2f9c58 !important;
        margin-top: -5px;
        font-size: 11px !important;
    }

}

@media (max-width: 420px) and (min-width: 376px){

    .styleImageOfProduct{
        height: 80px;
    }

    .styleCorProduct{
        font-size: 8px;
        width: 207px;
    }

    .styleImageProduct{
        /* background-color: #eeeff1; */
        height: 85px;
        transition: transform .5s ease;
    }
    .styleImageProduct2 {
        /* background-color: #eeeff1; */
        height: 66px;
        -webkit-transition: -webkit-transform .5s ease;
        transition: -webkit-transform .5s ease;
        transition: transform .5s ease;
        transition: transform .5s ease, -webkit-transform .5s ease;
        width: 158px;
        margin-top: 58px;
    }
    .styleSearchProduct{
        margin-left: -11px !important;
        width: 183px !important;
    }
    .styleQRCodeProduct{
        margin-left: -20px;
    }

    .inputForm{
        height: 30px;
        /* width: 80%; */
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0 1px 0 0 rgba(255,255,255,0.03);
        border: 1.5px solid #17a349;
    }

    .qrButton{
        min-height: 30px;	min-width: 30px;
        height: 30px;
    }

    .qrImage{
        height: 20px;
    }

    .searchButton {
        height: 28px;
        margin-left: 7px;
        margin-top: 1px;
    }

    .styleHotlineProduct{
        font-size: 10px;
        color: white;
        margin-left: 0px !important;
        margin-top: 4px;
        margin-right: -3px;
    }

    .styleButtonSm{
        width: 54px;
        margin-left: -4px !important;
        height: 20px;
        font-size: 10px !important;
    }

    .styleLangaueProduct{
        margin-left: 0px !important;
    }

    .styleContactProduct{
        font-size: 10px;
        color: white;
        margin-top: 4px;
        margin-left: 0px !important;
    }

    .styleNameProduct{
        font-weight: 900;
        color: #191919;
        font-size: 7px;
        font-family: Nunito;
    }

    .styleCardProduct{
        height: 200px;
        margin-left: 6px;
    }
    
    .styleCenterCor{
        display: flex;
        justify-content: center;
        height: 34px;
    }

    .MuiButton-label-161{
        font-size: 7px;
    }

    .HeightInfoProduct{
        height: 50px;
    }

    .styleSearchID{

    }

    .CustomInput-formControl-203 {
        margin: 0 0 17px 0;
        position: relative;
        padding-top: 9px;
        vertical-align: unset;
    }

    .InputSearchPortal{

    }

    .inputForm div div input{
        color: #2f9c58 !important;
        margin-top: -5px;
        font-size: 11px !important;
    }
}
.insideCardProduct{
    border-radius: 25px;
}

.d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .flex-centerY-centerX {
    justify-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .page-wrapper {
    height: 100%;
    display: table;
  }
  .page-wrapper:hover {
   transform: scale(1);
    box-shadow: 5px 20px 30px rgba(0,0,0,0.2);
  }
  .page-wrapper .page-inner {
    display: table-cell;
    vertical-align: middle;
  }
  
  .el-wrapper {
    width: 443px ;
    padding: 15px;
    margin: 15px auto;
    background-color: #fff;
  }
  
  @media (max-width: 991px) {
    .el-wrapper {
      width: 345px;
    }
  }
  
  @media (max-width: 768px) {
    .styleMarginAddress {
        text-align: justify;
        margin-top: 20px !important;
    }
    .el-wrapper {
      width: 290px;
      margin: 30px auto;
    }
    .contentnopadbar{
        width: 100% !important;
    }
  }
  
  .el-wrapper:hover .h-bg {
    left: 0px;
  }
  
  .el-wrapper:hover .price {
    /* left: 20px; */
    display: none;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #818181;
  }
  
  .el-wrapper:hover .add-to-cart {
    left: 50%;
  }
  
  .el-wrapper:hover .img {
    webkit-filter: blur(7px);
    -o-filter: blur(7px);
    -ms-filter: blur(7px);
    filter: blur(7px);
    filter: progid:DXImageTransform.Microsoft.Blur(pixelradius='7', shadowopacity='0.0');
    opacity: 0.4;
  }
  
  .el-wrapper:hover .info-inner {
    bottom: 155px;
  }
  
  .el-wrapper:hover .a-size {
    -webkit-transition-delay: 300ms;
    -o-transition-delay: 300ms;
    transition-delay: 300ms;
    bottom: 80px;
    opacity: 1;
  }
  
  .el-wrapper .box-down {
    width: 100%;
    height: 60px;
    position: relative;
    overflow: hidden;
  }
  
  .el-wrapper .box-up {
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;
    text-align: center;
  }
  
  .el-wrapper .img {
    padding: 20px 0;
    -webkit-transition: all 800ms cubic-bezier(0, 0, 0.18, 1);
    -moz-transition: all 800ms cubic-bezier(0, 0, 0.18, 1);
    -o-transition: all 800ms cubic-bezier(0, 0, 0.18, 1);
    transition: all 800ms cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -moz-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -o-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
  }
  
  .h-bg {
    -webkit-transition: all 800ms cubic-bezier(0, 0, 0.18, 1);
    -moz-transition: all 800ms cubic-bezier(0, 0, 0.18, 1);
    -o-transition: all 800ms cubic-bezier(0, 0, 0.18, 1);
    transition: all 800ms cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -moz-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -o-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    width: 660px;
    height: 100%;
    background-color: #464646;
    position: absolute;
    left: -659px;
  }
  
  .h-bg .h-bg-inner {
    width: 100%;
    height: 100%;
    background-color: rgb(15, 115, 67);
  }
  
  .info-inner {
    -webkit-transition: all 400ms cubic-bezier(0, 0, 0.18, 1);
    -moz-transition: all 400ms cubic-bezier(0, 0, 0.18, 1);
    -o-transition: all 400ms cubic-bezier(0, 0, 0.18, 1);
    transition: all 400ms cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -moz-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -o-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    position: absolute;
    width: 100%;
    bottom: 25px;
  }
  
  .info-inner .p-name,
  .info-inner .p-company {
    display: block;
  }
  
  .info-inner .p-name {
    font-family: 'Nunito', sans-serif;
    font-size: 18px;
    color: #252525;
    font-weight: 700;
  }
  
  .info-inner .p-company {
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    color: #8c8c8c;
  }
  
  .a-size {
    -webkit-transition: all 300ms cubic-bezier(0, 0, 0.18, 1);
    -moz-transition: all 300ms cubic-bezier(0, 0, 0.18, 1);
    -o-transition: all 300ms cubic-bezier(0, 0, 0.18, 1);
    transition: all 300ms cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -moz-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -o-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    position: absolute;
    width: 100%;
    bottom: -20px;
    font-family: 'PT Sans', sans-serif;
    color: #828282;
    opacity: 0;
  }
  
  .a-size .size {
    color: #252525;
  }
  
  .cart {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
  }
  
  .cart .price {
    -webkit-transition: all 600ms cubic-bezier(0, 0, 0.18, 1);
    -moz-transition: all 600ms cubic-bezier(0, 0, 0.18, 1);
    -o-transition: all 600ms cubic-bezier(0, 0, 0.18, 1);
    transition: all 600ms cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -moz-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -o-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    -webkit-transition-delay: 100ms;
    -o-transition-delay: 100ms;
    transition-delay: 100ms;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #252525;
    font-family: 'Nunito', sans-serif;
  }
  
  .cart .add-to-cart {
    -webkit-transition: all 600ms cubic-bezier(0, 0, 0.18, 1);
    -moz-transition: all 600ms cubic-bezier(0, 0, 0.18, 1);
    -o-transition: all 600ms cubic-bezier(0, 0, 0.18, 1);
    transition: all 600ms cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    -webkit-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -moz-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    -o-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
    /* ease-out */
    -webkit-transition-delay: 100ms;
    -o-transition-delay: 100ms;
    transition-delay: 100ms;
    display: block;
    position: absolute;
    top: 50%;
    left: 110%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .cart .add-to-cart .txt {
    font-size: 15px;
    color: #fff;
    letter-spacing: 0.045em;
    white-space: nowrap;
    font-family: 'Nunito', sans-serif;
  }
  
@media (max-width: 370px){
    

    .logoVBCHeader{
        height: 19px;
        min-height: 10px;
    }
    .styleSearchProduct{
        margin-left: -25px !important;
        width: 154px !important;
    }
    .styleQRCodeProduct{
        margin-left: -20px;
    }

    .inputForm{
        height: 30px;
        /* width: 80%; */
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0 1px 0 0 rgba(255,255,255,0.03);
        border: 1.5px solid #17a349;
    }

    .inputForm div div input{
        color: #2f9c58 !important;
        margin-top: -5px;
        font-size: 10px !important;
    }

    .qrButton{
        min-height: 30px;	min-width: 30px;
        height: 30px;
    }

    .qrImage{
        height: 20px;
    }

    .searchButton {
        height: 27px;
        margin-left: 10px;
        margin-top: 2px;
    }

    .styleHotlineProduct{
        font-size: 6px;
        color: white;
        margin-left: -15px !important;
        margin-top: 4px;
        margin-right: -3px;
    }

    .styleButtonSm{
        width: 42px;
        margin-left: -11px !important;
        height: 19px;
    }

    .styleButtonSm .span{
        font-size: 7px;
    }

    .styleLangaueProduct{
        margin-left: 0px !important;
    }

    .styleContactProduct{
        font-size: 6px;
        color: white;
        margin-top: 4px;
        margin-left: -25px !important;
    }

    .languageImgEn{
        max-height: 14px;
    }
    .languageImg{
        max-height: 14px;
    }
    .styleImageOfProduct {
        height: 56px;
    }

    .styleImageProduct {
        /* background-color: #eeeff1; */
        height: 71px;
        -webkit-transition: -webkit-transform .5s ease;
        transition: -webkit-transform .5s ease;
        transition: transform .5s ease;
        transition: transform .5s ease, -webkit-transform .5s ease;
    }

    .styleImageProduct2 {
        /* background-color: #eeeff1; */
        height: 58px;
        -webkit-transition: -webkit-transform .5s ease;
        transition: -webkit-transform .5s ease;
        transition: transform .5s ease;
        transition: transform .5s ease, -webkit-transform .5s ease;
        width: 111px;
        margin-top: 61px;

    }

    .cart .price {
        -webkit-transition: all 600ms cubic-bezier(0, 0, 0.18, 1);
        transition: all 600ms cubic-bezier(0, 0, 0.18, 1);
        -webkit-transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
        transition-timing-function: cubic-bezier(0, 0, 0.18, 1);
        -webkit-transition-delay: 100ms;
        transition-delay: 100ms;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 13px;
        color: #252525;
        font-family: 'Nunito', sans-serif;
    }
    
    .styleCorProduct {
        font-size: 6px;
        width: 207px;
    }

    .MuiButton-label-161{
        font-size: 5px;
    }

    .styleCardProduct {
        height: 160px;
        margin-left: 6px;
    }

    .HeightInfoProduct {
        height: 32px;
    }

    .CustomInput-formControl-203 {
        margin: 0 0 17px 0;
        position: relative;
        padding-top: 4px !important;
        vertical-align: unset;
    }

    .styleButtonSm span{
        font-size: 5px;
    }
}


.styleMarginMap{
    margin-left: 8.3% !important;
}

@media only screen and (min-width : 768px) and (max-width : 1023px){
    .styleMarginMap{
        margin-left: 0% !important;
    }
}

@media (max-width: 500px) {
    .styleMarginMap{
        margin-left: 0% !important;
    }
}


.styleSeeMore{
    margin-top: 10px;
    /* margin-left: 10px; */
    margin-bottom: -10px;
    color: #b9b9b9;
    display: flex;
    justify-content: center;
}

.paddingEmoji{
    display: flex;
    justify-content: center;
    padding: 20px 20px !important;
    height: 100px;
}
.iconHover{
    cursor: pointer;
}


.styleOverviewInfo{
    background-color: #fff;
    /* height: 0px; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #007ACE;
    font-size: 16px;
    font-style: normal;
    font-family: Nunito;
    font-weight: bold;
    letter-spacing: 0;
}

.textSample{
    color: #212B36;
    width: 37%;
    display: inline-block !important;
    padding: 5px;
    font-size: 16px;
    font-style: normal;
    background: #FCF1CD;
    font-family: Nunito;
    font-weight: 600;
    margin-bottom: 10px;
    letter-spacing: 0;
}

@media only screen and (min-width : 768px) and (max-width : 1024px){
    .textSample{
        width: 37%;
    }
}

@media only screen and (max-width : 750px){
    .textSample{
        width: 80%;
    }
}

@media only screen and (max-width : 375px){
    .textSample{
        width: 90%;
    }
}
.stikcyBar{
position: -webkit-sticky;
position: sticky;
top: 0;
}
.contentnopadbar{
    /* //position: sticky; */
    bottom: -30px;
    margin-top: -50px;
    width: 100%;
    width: 919px;
}

.sticky{
    position: sticky;
    z-index: 1000;
    top: -30px;
    width: 100%;
}
.videotag{
    font-family: 'Nunito', sans-serif;
    font-size: 25px;
    font-weight: 700;
    /* margin-left: 13px; */
}




@media (max-width: 500px){
    .contentnopadbar{
        width: 100% !important;
    }
    /* .buttonOfTab {
        width: 339px !important;
        height: 63px !important;
    } */
}

@media (max-width: 320px){
    .paddingEmoji {
        display: flex;
        justify-content: center;
        height: 75px;
        width: 30px !important;
        padding: 0px 0px !important;
       
    }
/* 
    .imageOfTab {
        height: 34px !important;
        padding: 10px 10px;
    }

    .buttonOfTab {
        width: 340px !important;
        height: 50px !important;
        max-width: 200% !important;
    } */
}
.ctrl {
    padding: 8px 5px;
    width: 30px;
    text-align: center;
    background: rgba(200, 200, 200, 0.8);
    display: inline-block;
    cursor: pointer;
}
.ctrl:hover {
    background: rgba(200, 200, 200, 1);
}

.buttonNext{
    position: absolute;
    margin-top: -40px;
    margin-left: 4px;
}
.buttonNext:fullscreen{
    z-index: 1000;
    position: absolute !important;
    bottom: 0px !important;
    left: 4px important;
}
#panorama {
    width: 100%;
    height: 400px;
}
#controls {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    width: 100%;
    padding-bottom: 3px;
}
.ctrl {
    padding: 8px 5px;
    width: 30px;
    text-align: center;
    background: rgba(200, 200, 200, 0.8);
    display: inline-block;
    cursor: pointer;
}
.ctrl:hover {
    background: rgba(200, 200, 200, 1);
}
.ril-image-current {
    max-width: none !important;
    max-height: none !important;
    }