#profileIcon {
    -webkit-animation: swing 2s infinite linear;
}
#treeIcon {
    -webkit-animation: swing 2s infinite linear;
}

#carIcon {
    -webkit-animation: swing 2s infinite linear;
}

#infoIcon {
    -webkit-animation: swing 2s infinite linear;
}
#addressIcon {
    -webkit-animation: swing 2s infinite linear;
}


@-webkit-keyframes swing {
     20% { transform: rotate(15deg); } 
40% { transform: rotate(-10deg); } 
60% { transform: rotate(5deg); } 
80% { transform: rotate(-5deg); } 
100% { transform: rotate(0deg); } 
}