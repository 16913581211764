.btn:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.btn {
    color: #fff;
}

.btn-red-gradient {
    border: #ff6161;
    background: linear-gradient(90deg,#ff904c 8%,#ff6161 98%);
}

.btn-red-gradient:focus {
    border: #ff6161;
    background: linear-gradient(90deg,#ff904c 8%,#ff6161 98%);
}

.btn-blue-gradient {
    border: #0078fb;
    background: linear-gradient(0deg,#00b0e5 8%,#0078fb 89%);
}

.btn-green-gradient {
    border: #0fd186;
    background: linear-gradient(0deg,#37e586 8%,#0fd186 89%);
}

.btn-gray-gradient {
    border: #37b2ae;
    background: linear-gradient(0deg,#20c497 8%,#37b2ae 89%);
}

.btn-purple-gradient {
    border: #5e76eb;
    background: linear-gradient(0deg,#a15ef3 8%,#5e76eb 89%);
}

.btn-orange-gradient {
    border: #ff7b02;
    background: linear-gradient(0deg,#ffb33a 8%,#ff7b02 88%);
}

.bg-red-gradient {
    border: #ff6161;
    background: linear-gradient(90deg,#ff904c 8%,#ff6161 98%);
}

.bg-red-gradient:focus {
    border: #ff6161;
    background: linear-gradient(90deg,#ff904c 8%,#ff6161 98%);
}

.bg-blue-gradient {
    border: #0078fb;
    background: linear-gradient(0deg,#00b0e5 8%,#0078fb 89%);
}

.bg-green-gradient {
    border: #0fd186;
    background: linear-gradient(0deg,#37e586 8%,#0fd186 89%);
}

.bg-gray-gradient {
    border: #37b2ae;
    background: linear-gradient(0deg,#20c497 8%,#37b2ae 89%);
}

.bg-purple-gradient {
    border: #5e76eb;
    background: linear-gradient(0deg,#a15ef3 8%,#5e76eb 89%);
}

.bg-orange-gradient {
    border: #ff7b02;
    background: linear-gradient(0deg,#ffb33a 8%,#ff7b02 88%);
}

.bg-info-gradient {
    border: #0cc2ed;
    background: linear-gradient(0deg,#79d1fe 8%,#0cc2ed 89%);
}

.text-orange {
    color: #ff7b02;
}

.text-red {
    color: #E4261E;
}

.text-dark {
    color: #0000;
}




